import Home from "./pages/Home";
import About from "./pages/About";
import DrumMachine from "./pages/DrumMachine";
import InputBoxes from "./pages/InputBoxes";
import Calculator from "./pages/Calculator";
import { BrowserRouter, Routes, Route } from "react-router-dom";


function App() {
  return (
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/drum-machine" element={<DrumMachine />} />
          <Route path="/input-boxes" element={<InputBoxes />} />
          <Route path="/calculator" element={<Calculator />} />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
