import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { marked } from 'marked';
import '../App.css';

const Operations = () => {
    const [inputValue, setInputValue] = useState(''); // No default value
    const [results, setResults] = useState([]);
    const [error, setError] = useState(''); // To display error messages

    // Updates the state with the current value in the input field
    const changeInput = (event) => {
        setInputValue(event.target.value); // Keep as string for empty input check
        setError(''); // Clear error message when user types
    };

    // Handler for the button click to perform operations
    const performOperations = () => {
        if (!inputValue || isNaN(inputValue)) {
            setError('Please enter a number');
            setResults([]);
            return;
        }
        setError('');
        const num = parseFloat(inputValue);
        const addResult = Math.round((num + 5)*10,1)/10;
        const subtractResult = Math.round((num - 3)*10,1)/10;
        const multiplyResult = Math.round((num * 4)*10,1)/10;
        const divideResult = Math.round((num / 2)*10,1)/10;
        setResults([
            { title: 'Adding 5', value: addResult },
            { title: 'Subtracting 3', value: subtractResult },
            { title: 'Multiplying 4', value: multiplyResult },
            { title: 'Dividing 2', value: divideResult }
        ]);
    };

    return (
        <div>
            <div className='operations'>
                <p>Math Operations with a Number</p>
                <div>
                    <div>
                        <input
                            type='text' // Changed to text to manage value as string
                            value={inputValue}
                            onChange={changeInput}
                            id='numberInput'
                            style={{ appearance: 'textfield' }} // Removes up/down arrows
                            
                        />
                    </div>
                    <div>
                        <button onClick={performOperations}>Let's see some math!</button>
                    </div>
                </div>
                <div>
                    {error && <p style={{ color: 'gray' }}>{error}</p>}
                    <div className="results-container">
                    {results.map((result, index) => (
                        <div key={index} className="result-block">
                            <div className="title">{result.title}</div>
                            <div className="value">{result.value}</div>
                        </div>
                    ))}
                </div>
                </div>
            </div>
        </div>
    );
};

const Markdown = () => {
  const [textarea, setTextarea] = useState(
    `# a heading element (H1 size) 
## a sub heading element (H2 size), 
some inline code: \`<div></div>\`
and code block 
\`\`\`
Some code block
with multiple
lines
\`\`\`

[links](https://www.freecodecamp.org), and
> Block Quotes


- And of course, there are lists.
  - Some are bulleted.
     - With different indentation levels.
        - That look like this.

1. And there are numbered lists too.
1. Use just 1s if you want!

a list item, a blockquote, an image, and 
    **bolded text.**`
  );

  const handleChange = (event) => {
    setTextarea(event.target.value);
  };

  return (
    <div>
        <div className='markdown'>
            <div> 
                <p>Markdown Editor</p>
                <textarea 
                id='editor' 
                className='editor'
                value={textarea} 
                onChange={handleChange} 
                placeholder='Enter Markdown text here...'
                />
            </div>
            <div 
                className='container'
                id='preview'
                dangerouslySetInnerHTML={{ __html: marked(textarea)}}
            >
            </div>
        </div>
    </div>
  );
};

const InputBoxes = () => {
    return (
        <div>
            <Operations />
            <Markdown />
        </div>
    );
}

export default InputBoxes;

