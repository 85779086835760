import '../App.css';
import { createClient } from '@supabase/supabase-js'
import { useState } from 'react';

/*

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY
const supabase = createClient(supabaseUrl, supabaseKey)

const EmailForm = () => {
  const [email, setEmail] = useState('');
  const [submittedEmail, setSubmittedEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { error } = await supabase.from('email-form').insert([{ email: email }]);
      if (error) {
        throw error;
      }
      console.log('Email submitted:', email);
      setSubmittedEmail(email); // Set submitted email for success message
      setEmail(''); // Reset email input
    } catch (error) {
      console.error('Error submitting email:', error.message);
    }
  };

  return (
    <div>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button type="submit">Submit</button>
        </form>
        {submittedEmail && (
        <p>Your email {submittedEmail} was submitted successfully.</p>
        )}
    </div>
  );
};

function NewHome () {
  return (
    <div>
      <div className='bg1'>
        <div  className='home-background'>
          <img className='bmt-background-image' src='https://uploads-ssl.webflow.com/65cebf94f3b42b304173c9ac/65cecb9934d3248e36e06ecd_Frame%2013%20(1).png'></img>
        </div>
        <div className='home-container'>
          <h1 className='h1-logo'>BUILD MY TOOLS</h1>
          <p className='subtitle'>Any engineering effort spent building internal tools is effort spent not creating value.</p>
          <p>We are an internal tools outsourcing service. 
            <br></br>
            <br></br>
            Cheaper than an engineer. Faster than building it yourself.</p>
            <div>
          <EmailForm />
          </div>
        </div>
      </div>
      

      
    </div>
  );
}

*/

function Home () {
  return (
    <div className="About">
      <p>consider this my public sandbox</p>
      <p>-AG</p>
    </div>
  )
}

export default Home;
