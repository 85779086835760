import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

const InputButton = ({ inputID, text, onInputClick }) => {
  return (
    <div>
      <button onClick={onInputClick} id={inputID} className="input button">
        {text}
      </button>
    </div>
  );
};

const OperationButton = ({ operationID, text, onOperationClick }) => {
  return (
    <div>
      <button
        onClick={onOperationClick}
        id={operationID}
        className="operation button"
      >
        {text}
      </button>
    </div>
  );
};

const NumberButton = ({ numberID, text, onNumberClick }) => {
  return (
    <div>
      <button
        onClick={onNumberClick}
        id={numberID}
        value={text}
        className="number button"
      >
        {text}
      </button>
    </div>
  );
};

const Display = ({ onInputChange }) => {
  return (
    <div className="display" id="display">
      <p>{onInputChange}</p>
    </div>
  );
};

function Calculator() {
  //initial state of num is 0, get current state of number, set state with new num by doing newNum = oldNum*10+typedNum
  //display state of num in display
  const [firstNumber, setFirstNumber] = useState(0);
  const [secondNumber, setSecondNumber] = useState(0);
  const [thirdNumber, setThirdNumber] = useState(0);
  const [operation, setOperation] = useState("");
  const [display, setDisplay] = useState("first"); //toggle what to show on screen
  const [isDecimal, setIsDecimal] = useState(false); //if a number is a decimal
  const [decimalPlaces, setDecimalPlaces] = useState(0); // set Decimal places
  const [error, setError] = useState(""); // To display error messages

  // handle clicking numbers
  const handleNumberClick = (number) => {
    if (number === "decimal" && isDecimal) {
      // do nothing
    } else if (number === "decimal" && !isDecimal) {
      setFirstNumber(firstNumber + ".");
      setIsDecimal(true);
    } else if (number !== "decimal" && isDecimal) {
      if (number === 0) {
        setFirstNumber(`${firstNumber}0`);
        setDecimalPlaces(decimalPlaces + 1);
      } else {
        if (decimalPlaces === 0) {
          setFirstNumber(parseFloat(firstNumber + number)); // concat firstnumber with decimal with the first number after decimal
          setDecimalPlaces(decimalPlaces + 1);
        } else {
          if (firstNumber[firstNumber.length - 1] == 0) {
            setFirstNumber(parseFloat(firstNumber + number));
          } else {
            setFirstNumber(
              parseFloat(firstNumber + number / 10 ** (decimalPlaces + 1))
            );
          }
          setDecimalPlaces(decimalPlaces + 1);
        }
      }
    } else {
      if (thirdNumber !== 0) {
        setSecondNumber(thirdNumber);
        setThirdNumber(0);
      }
      setDisplay("first");
      setFirstNumber(firstNumber * 10 + number);
      setError("");
    }
  };

  // handle clicking clear and equals
  const handleInputClick = (input) => {
    setIsDecimal(false);
    setDecimalPlaces(0);
    if (input === "clear") {
      setFirstNumber(0);
      setSecondNumber(0);
      setThirdNumber(0);
      setOperation("");
      setDisplay("first");
      setError("");
    } else if (input === "equals") {
      if (operation === "add") {
        if (thirdNumber === 0) {
          setThirdNumber(parseFloat(secondNumber) + parseFloat(firstNumber));
          setSecondNumber(parseFloat(firstNumber));
        } else {
          setThirdNumber(parseFloat(secondNumber) + parseFloat(thirdNumber));
        }
        setFirstNumber(0);
        setDisplay("third");
      } else if (operation === "subtract") {
        if (thirdNumber === 0) {
          setThirdNumber(parseFloat(secondNumber) - parseFloat(firstNumber));
          setSecondNumber(parseFloat(firstNumber));
        } else {
          setThirdNumber(parseFloat(thirdNumber) - parseFloat(secondNumber));
        }
        setFirstNumber(0);
        setDisplay("third");
      } else if (operation === "multiply") {
        if (thirdNumber === 0) {
          setThirdNumber(parseFloat(secondNumber) * parseFloat(firstNumber));
          setSecondNumber(parseFloat(firstNumber));
        } else {
          setThirdNumber(parseFloat(thirdNumber) * parseFloat(secondNumber));
        }
        setFirstNumber(0);
        setDisplay("third");
      } else if (operation === "divide") {
        if (thirdNumber === 0) {
          setThirdNumber(
            parseFloat(secondNumber) / parseFloat(firstNumber),
            roundThird(thirdNumber)
          );
          setSecondNumber(parseFloat(firstNumber));
        } else {
          setThirdNumber(
            parseFloat(thirdNumber) / parseFloat(secondNumber),
            roundThird(thirdNumber)
          );
        }
        setFirstNumber(0);
        setDisplay("third");
      }
    }
  };

  // set operations & run them
  const handleOperationClick = (nextOperation) => {
    setIsDecimal(false);
    setDecimalPlaces(0);
    if (firstNumber !== 0 && thirdNumber === 0) {
      if (secondNumber !== 0) {
        if (operation === "add") {
          setThirdNumber(secondNumber + firstNumber);
          setDisplay("third");
        } else if (operation === "subtract") {
          setThirdNumber(secondNumber - firstNumber);
          setDisplay("third");
        } else if (operation === "multiply") {
          setThirdNumber(secondNumber * firstNumber);
          setDisplay("third");
        } else if (operation === "divide") {
          setThirdNumber(secondNumber / firstNumber, roundThird(thirdNumber));
          setDisplay("third");
        }
      } else {
        setDisplay("second");
      }
    } else if (firstNumber === 0 && thirdNumber !== 0) {
      setDisplay("third");
    }
    if (firstNumber !== 0) {
      if (secondNumber === 0) {
        setDisplay("second");
      }
      setSecondNumber(firstNumber);
      setFirstNumber(0);
    }
    setOperation(nextOperation);
  };

  //rounding
  const countDecimals = (value) => {
    if (Math.floor(value) !== value) {
      return value.toString().split(".")[1].length || 0;
    } else {
      return 0;
    }
  };

  const roundFirst = (num) => {
    if (countDecimals(num) !== 0) {
      setFirstNumber(Math.round((num + Number.EPSILON) * 10000) / 10000);
    }
  };

  const roundSecond = (num) => {
    if (countDecimals(num) !== 0) {
      setSecondNumber(Math.round((num + Number.EPSILON) * 10000) / 10000);
    }
  };

  const roundThird = (num) => {
    if (countDecimals(num) !== 0) {
      setThirdNumber(Math.round((num + Number.EPSILON) * 10000) / 10000);
    }
  };

  useEffect(() => {
    roundFirst(firstNumber);
    roundSecond(secondNumber);
    roundThird(thirdNumber);
  }, [secondNumber, thirdNumber]);

  // render display
  const handleInputChange = () => {
    if (display === "first") {
      return firstNumber;
    } else if (display === "second") {
      return secondNumber;
    } else if (display === "third") {
      return thirdNumber;
    } else {
      return "error";
    }
  };

  return (
    <div className='calculator-display'>
    <div className="calculator">
      <Display onInputChange={handleInputChange()} />
      <div className="top-row">
        <InputButton
          inputID="clear"
          text="CLEAR"
          onInputClick={() => handleInputClick("clear")}
        />
        <InputButton
          inputID="equals"
          text="="
          onInputClick={() => handleInputClick("equals")}
        />
      </div>
      <div className="number-block">
        <div className="numbers-rows">
          <NumberButton
            numberID="nine"
            text="9"
            onNumberClick={() => handleNumberClick(9)}
          />
          <NumberButton
            numberID="eight"
            text="8"
            onNumberClick={() => handleNumberClick(8)}
          />
          <NumberButton
            numberID="seven"
            text="7"
            onNumberClick={() => handleNumberClick(7)}
          />
          <NumberButton
            numberID="six"
            text="6"
            onNumberClick={() => handleNumberClick(6)}
          />
          <NumberButton
            numberID="five"
            text="5"
            onNumberClick={() => handleNumberClick(5)}
          />
          <NumberButton
            numberID="four"
            text="4"
            onNumberClick={() => handleNumberClick(4)}
          />
          <NumberButton
            numberID="three"
            text="3"
            onNumberClick={() => handleNumberClick(3)}
          />
          <NumberButton
            numberID="two"
            text="2"
            onNumberClick={() => handleNumberClick(2)}
          />
          <NumberButton
            numberID="one"
            text="1"
            onNumberClick={() => handleNumberClick(1)}
          />
          <NumberButton
            numberID="zero"
            text="0"
            onNumberClick={() => handleNumberClick(0)}
          />
          <NumberButton
            numberID="decimal"
            text="."
            onNumberClick={() => handleNumberClick("decimal")}
          />
        </div>
        <div className="operations-row">
          <OperationButton
            operationID="add"
            text="+"
            onOperationClick={() => handleOperationClick("add")}
          />
          <OperationButton
            operationID="subtract"
            text="-"
            onOperationClick={() => handleOperationClick("subtract")}
          />
          <OperationButton
            operationID="multiply"
            text="x"
            onOperationClick={() => handleOperationClick("multiply")}
          />
          <OperationButton
            operationID="divide"
            text="÷"
            onOperationClick={() => handleOperationClick("divide")}
          />
        </div>
      </div>
    </div>
    </div>
  );
}

export default Calculator;