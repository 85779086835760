import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

function useKeydown(key, action) {
  useEffect(() => {
    const handleKeydown = (event) => {
      if (event.key.toUpperCase() === key.toUpperCase()) {
        action();
      }
    };

    window.addEventListener('keydown', handleKeydown);
    return () => {
      window.removeEventListener('keydown', handleKeydown);
    };
  }, [key, action]);
}

const DrumSounds = {
  heaterOne: 'https://s3.amazonaws.com/freecodecamp/drums/Heater-1.mp3',
  heaterTwo: 'https://s3.amazonaws.com/freecodecamp/drums/Heater-2.mp3',
  heaterThree: 'https://s3.amazonaws.com/freecodecamp/drums/Heater-3.mp3',
  heaterFour: 'https://s3.amazonaws.com/freecodecamp/drums/Heater-4_1.mp3',
  clap: 'https://s3.amazonaws.com/freecodecamp/drums/Heater-6.mp3',
  openHH: 'https://s3.amazonaws.com/freecodecamp/drums/Dsc_Oh.mp3',
  kickNHat: 'https://s3.amazonaws.com/freecodecamp/drums/Kick_n_Hat.mp3',
  kick: 'https://s3.amazonaws.com/freecodecamp/drums/RP4_KICK_1.mp3',
  closedHH: 'https://s3.amazonaws.com/freecodecamp/drums/Cev_H2.mp3'
}

const DrumButton = ({ keyText, keyID }) => {
  
  const playSound = () => {
    const audio = document.getElementById(keyText);
    audio.currentTime = 0;
    audio.play();
  };

  useKeydown(keyText, playSound);

  return (
    <div>
      <button 
        className='drum-pad'
        id={keyID}
        onClick={playSound}> {keyText}
        <audio
          className='clip'
          id={keyText}
          src={DrumSounds[keyID]}>
        </audio>          
      </button>
    </div>
  );
};

const DrumMachine = () => {
  return (
    <div className='drum-machine' id='drum-machine'>
      <p>Drum machine</p>
      <DrumButton keyText='Q' keyID='heaterOne' />
      <DrumButton keyText='W' keyID='heaterTwo' />
      <DrumButton keyText='E' keyID='heaterThree' />
      <DrumButton keyText='A' keyID='heaterFour' />
      <DrumButton keyText='S' keyID='clap' />
      <DrumButton keyText='D' keyID='openHH' />
      <DrumButton keyText='Z' keyID='kickNHat' />
      <DrumButton keyText='X' keyID='kick' />
      <DrumButton keyText='C' keyID='closedHH' />
      <div id='display'></div>
    </div>
  );
};


export default DrumMachine;