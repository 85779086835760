import '../App.css';

function About() {
  return (
    <div className="About">
        <p>consider this my public sandbox</p>
        <p>-AG</p>

    </div>
  );
}

export default About;
